import {
  intermediaryStates,
  investeeStates,
  investorStates,
  matchStates,
} from "@/platforms/prosper/states";

const localInvesteeState = {
  ...investeeStates,
  hasPendingUpdates: {
    value: "hasPendingUpdates",
    label: "Has Updates",
    parent: "inProgress",
    color: "inProgress",
    chartColor: "#7239EA",
    order: 3,
  },
};
const orderPerRole = {
  investee: [
    "application",
    "qualified",
    "review",
    "preparation",
    "ready",
    "published",
  ],
  investor: [
    "application",
    "review",
    "onboarding",
    "published",
    "published-listed",
  ],
  intermediary: ["application", "review", "onboarding", "published"],
  match: ["interest", "talks"],
};

const bottomLinks = {
  investee: [
    {
      label: "Actions needed",
      links: [
        {
          link: "/investees?count=10&offset=0&hasPendingUpdates=true",
          status: "hasPendingUpdates",
          text: "Has updates",
          bg: "bg-info",
          entity: "investee",
          disabled: false,
        },
        {
          link: "/investees?count=10&offset=0&state=ready",
          status: "ready",
          text: "Ready to publish",
          bg: "bg-success",
          entity: "investee",
          disabled: false,
        },
      ],
    },
    {
      label: "Exited Workflow",
      links: [
        {
          link: "/investees?count=10&offset=0&state=unqualified",
          status: "unqualified",
          text: "Rejected",
          bg: "bg-danger",
          entity: "investee",
          disabled: false,
        },
        {
          link: "/investees?count=10&offset=0&state=closed",
          status: "closed",
          text: "Success",
          bg: "bg-success",
          entity: "investee",
          disabled: false,
        },
        {
          link: "/investees?count=10&offset=0&state=archived",
          status: "archived",
          text: "Archived",
          bg: "bg-danger",
          entity: "investee",
          disabled: false,
        },
      ],
    },
  ],
  investor: [
    {
      label: "Actions needed",
      links: [
        {
          link: "/investors?count=10&offset=0&state=review",
          status: "review",
          text: "Complete Applications Review",
          bg: "bg-info",
          entity: "investor",
          disabled: false,
        },
      ],
    },
    {
      label: "Exited Workflow",
      links: [
        {
          link: "/investors?count=10&offset=0&state=unqualified",
          status: "unqualified",
          text: "Unqualified",
          bg: "bg-danger",
          entity: "investor",
          disabled: false,
        },
        {
          link: "/investors?count=10&offset=0&state=archived",
          status: "archived",
          text: "Archived",
          bg: "bg-danger",
          entity: "investor",
          disabled: false,
        },
      ],
    },
  ],
  intermediary: [
    {
      label: "Actions needed",
      links: [
        {
          link: "/intermediaries?count=10&offset=0&state=review",
          status: "review",
          text: "Complete Applications Review",
          bg: "bg-info",
          entity: "intermediary",
          disabled: false,
        },
      ],
    },
    {
      label: "Exited Workflow",
      links: [
        {
          link: "/intermediaries?count=10&offset=0&state=unqualified",
          status: "unqualified",
          text: "Unqualified",
          bg: "bg-danger",
          entity: "intermediary",
          disabled: false,
        },
        {
          link: "/intermediaries?count=10&offset=0&state=archived",
          status: "archived",
          text: "Archived",
          bg: "bg-danger",
          entity: "intermediary",
          disabled: false,
        },
      ],
    },
  ],
  match: [
    {
      label: "Exited Workflow",
      links: [
        {
          link: "/matches?count=10&offset=0&status=closed",
          status: "closed",
          text: "Closed successfully",
          bg: "bg-success",
          entity: "match",
          disabled: false,
        },
        {
          link: "/matches?count=10&offset=0&status=abandon",
          status: "abandon",
          text: "Archived",
          bg: "bg-danger",
          entity: "match",
          disabled: false,
        },
      ],
    },
  ],
};

const getChartData = (data, entity = "investee") => {
  const order = orderPerRole[entity];
  const maps = {
    investee: localInvesteeState,
    intermediary: intermediaryStates,
    investor: investorStates,
    match: matchStates,
  };

  const map = maps[entity];

  const dataByStatus = {};
  for (let item in data) {
    const index = data[item]?._id?.state || data[item]._id;
    const status = map[index];
    if (!status) {
      continue;
    }
    dataByStatus[status.value] = {
      name: status.label || status.value,
      chartColor: status.chartColor,
      count: data[item].count,
    };
  }

  const dataSet = order.map((item) => {
    let statusData = dataByStatus[item];
    if (!statusData) {
      statusData = map[item];
    }
    return {
      x: statusData?.name || "",
      y: statusData?.count || 0,
      fillColor: statusData?.chartColor || "",
    };
  });

  return dataSet;
};

const getStatusCounts = (state, entity = "investee") => {
  const response = {};

  const maps = {
    investee: localInvesteeState,
    intermediary: intermediaryStates,
    investor: investorStates,
    match: matchStates,
  };

  const map = maps[entity];

  for (let item in map) {
    const name = map[item].value;
    response[name] = { count: 0 };
  }

  const data = state.statusCount?.[entity];

  for (let item in data) {
    const index = data[item]?._id?.state || data[item]._id;

    const status = map[index];
    if (!status) {
      continue;
    }
    response[status.value].count = data[item].count;
  }

  return response;
};
export { getChartData, getStatusCounts, bottomLinks };
