import { PublicClientApplication } from "@azure/msal-browser";
import config from "@/config/app.config";

export const msalConfig = {
  auth: {
    clientId: config.aadClientId,
    authority: config.aadAuthority,
    redirectUri: config.aadRedirectUri,
    postLogoutRedirectUri: config.aadPostLogoutRedirectUri,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
