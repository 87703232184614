import { investeeStates, investorStates } from "@/platforms/awf/states";

const localInvesteeState = {
  ...investeeStates,
};
const orderPerRole = {
  investee: [
    "application",
    "applied",
    "rejected",
    "inWorkplan",
    "grantImplementation",
    "published",
  ],
  investor: ["review", "qualified", "investment", "unqualified"],
};

const bottomLinks = {
  investee: [
    {
      label: "Actions needed",
      links: [
        {
          link: "/investees?count=10&offset=0&state=application",
          status: "application",
          text: "Application",
          bg: "bg-info",
          entity: "investee",
          disabled: false,
        },
        {
          link: "/investees?count=10&offset=0&state=applied",
          status: "applied",
          text: "Applied",
          bg: "bg-success",
          entity: "investee",
          disabled: false,
        },
      ],
    },
    {
      label: "Exited Workflow",
      links: [
        {
          link: "/investees?count=10&offset=0&state=rejected",
          status: "rejected",
          text: "Rejected",
          bg: "bg-danger",
          entity: "investee",
          disabled: false,
        },
        {
          link: "/investees?count=10&offset=0&state=archived",
          status: "archived",
          text: "Archived",
          bg: "bg-danger",
          entity: "investee",
          disabled: false,
        },
      ],
    },
  ],
  investor: [
    {
      label: "Actions needed",
      links: [
        {
          link: "/investors?count=10&offset=0&state=review",
          status: "review",
          text: "Complete Applications Review",
          bg: "bg-info",
          entity: "investor",
          disabled: false,
        },
      ],
    },
    {
      label: "Exited Workflow",
      links: [
        {
          link: "/investors?count=10&offset=0&state=unqualified",
          status: "unqualified",
          text: "Unqualified",
          bg: "bg-danger",
          entity: "investor",
          disabled: false,
        },
        {
          link: "/investors?count=10&offset=0&state=archived",
          status: "archived",
          text: "Archived",
          bg: "bg-danger",
          entity: "investor",
          disabled: false,
        },
      ],
    },
  ],
};

const getChartData = (data, entity = "investee") => {
  const order = orderPerRole[entity];
  const maps = {
    investee: localInvesteeState,
    investor: investorStates,
  };

  const map = maps[entity];

  const dataByStatus = {};
  for (let item in data) {
    const index = data[item]?._id?.state || data[item]._id;
    const status = map[index];
    if (!status) {
      continue;
    }
    dataByStatus[status.value] = {
      name: status.label || status.value,
      chartColor: status.chartColor,
      count: data[item].count,
    };
  }

  const dataSet = order.map((item) => {
    let statusData = dataByStatus[item];
    if (!statusData) {
      statusData = map[item];
    }
    return {
      x: statusData?.name || "",
      y: statusData?.count || 0,
      fillColor: statusData?.chartColor || "",
    };
  });

  return dataSet;
};

const getStatusCounts = (state, entity = "investee") => {
  const response = {};

  const maps = {
    investee: localInvesteeState,
    investor: investorStates,
  };

  const map = maps[entity];

  for (let item in map) {
    const name = map[item].value;
    response[name] = { count: 0 };
  }

  const data = state.statusCount?.[entity];

  for (let item in data) {
    const index = data[item]?._id?.state || data[item]._id;

    const status = map[index];
    if (!status) {
      continue;
    }
    response[status.value].count = data[item].count;
  }

  return response;
};
export { getChartData, getStatusCounts, bottomLinks };
