const actions = {
  send({ commit }, payload) {
    // add external log service if needed
    console.log(payload);
  },
};

export default {
  namespaced: true,
  actions,
};
