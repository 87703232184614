import { msalInstance } from "./msal.config";
import { useStore } from "vuex";
import _ from "lodash";
import { event } from "vue-gtag";
import config from "@/config/app.config";

export function msalService() {
  const store = useStore();

  const initialize = async () => {
    try {
      await msalInstance.initialize(); // Call the initialize function
    } catch (error) {
      console.log("Initialization error", error);
    }
  };

  const login = async () => {
    try {
      // Check if MSAL is initialized before using it
      if (!msalInstance) {
        throw new Error(
          "MSAL not initialized. Call initializeMsal() before using MSAL API."
        );
      }
      await msalInstance.loginRedirect();
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const logout = async () => {
    if (!msalInstance) {
      throw new Error(
        "MSAL not initialized. Call initializeMsal() before using MSAL API."
      );
    }
    await msalInstance.logoutRedirect();
  };

  const handleRedirect = async () => {
    try {
      await msalInstance.handleRedirectPromise();
      const adUser = msalInstance.getAllAccounts()[0];
      msalInstance.setActiveAccount(adUser);

      const token = await getToken();
      await store.dispatch("user/storeActiveDirectorySession", {
        adUser,
        token,
      });
      await event("login", {
        event_category: "account",
        event_label: "Login success",
      });
    } catch (error) {
      console.error("Redirect error:", error);
    }
  };

  const getToken = async () => {
    if (!msalInstance) {
      throw new Error(
        "MSAL not initialized. Call initializeMsal() before using MSAL API."
      );
    }
    try {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length === 0) {
        throw new Error("No accounts found. Please login first.");
      }
      const silentRequest = {
        scopes: config.aadTokenScopes,
        account: accounts[0],
      };
      const silentResponse = await msalInstance.acquireTokenSilent(
        silentRequest
      );
      return silentResponse.accessToken;
    } catch (error) {
      console.error("Token acquisition error:", error);
    }
  };

  return {
    initialize,
    login,
    logout,
    getToken,
    handleRedirect,
  };
}
