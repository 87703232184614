const states = {
  application: {
    value: "application",
    label: "New",
    parent: "new",
    color: "inProgress",
    order: 1,
  },
  review: {
    value: "review",
    label: "Review",
    parent: "inProgress",
    color: "inProgress",
    chartColor: "#95c5ec",
    order: 2,
  },
  onboarding: {
    value: "onboarding",
    label: "Prosper validation",
    parent: "inProgress",
    color: "inProgress",
    chartColor: "#208ce1",
    order: 3,
  },
  published: {
    value: "published",
    label: "Pre-cleared",
    parent: "published",
    color: "done",
    chartColor: "#2d9ff7",
    order: 4,
  },
  "published-listed": {
    value: "published-listed",
    label: "Listed",
    parent: "published",
    color: "done",
    chartColor: "#2d9ff7",
    order: 5,
  },
  unqualified: {
    value: "unqualified",
    label: "Unqualified",
    parent: "closed",
    color: "denied",
    chartColor: "#c0c0c0",
    order: 6,
  },
  archived: {
    value: "archived",
    label: "Archived",
    parent: "closed",
    color: "denied",
    chartColor: "#c0c0c0",
    order: 7,
  },
};

module.exports = states;
