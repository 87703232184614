import axios from "@/core/services/axios";
import _ from "lodash";
import {
  SET_LIST,
  CLEAR_LIST,
  SET_CURRENT,
  SET_ADVISORS_INFO,
  SET_INTERMEDIARY_LIST,
  SET_MATCH_LIST,
} from "./mutation-types";
import router from "@/router/clean";
import store from "@/store";

const state = {
  list: [],
  current: {},
  advisorsInformation: [],
  intermediaryList: {
    type: "",
    list: [],
  },
  matchList: [],
};

const mutations = {
  [SET_LIST](state, list) {
    state.list = list;
  },
  [CLEAR_LIST](state) {
    state.list = [];
  },
  [SET_CURRENT](state, current) {
    state.current = current;
  },
  [SET_ADVISORS_INFO](state, payload) {
    state.advisorsInformation = payload;
  },
  [SET_INTERMEDIARY_LIST](state, payload) {
    state.intermediaryList = payload;
  },
  [SET_MATCH_LIST](state, payload) {
    state.matchList = payload;
  },
};

const actions = {
  getSubmissionData({ commit, getters }, id) {
    return axios
      .get(
        "/alien/admin/submissions/" +
          id +
          `?entity=${getters["getCurrentRole"]}&platform=${store.getters["platform/platformId"]}`
      )
      .then((res) => {
        const submission = _.get(res, "data.items[0]");
        if (submission) {
          commit(SET_CURRENT, submission);
        }
      });
  },
  getListData({ commit }, paginatedPath) {
    let path = `/alien/submissions?platform=${store.getters["platform/platformId"]}`;
    if (paginatedPath) {
      path = `${paginatedPath}&platform=${store.getters["platform/platformId"]}`;
    }
    return axios.get(path).then((res) => {
      commit(SET_LIST, res.data.items);
      return res;
    });
  },
  getReportData(_, path = "/alien/submissions") {
    return axios.get(path);
  },
  createSubmission({ getters, commit }, payload) {
    const data = {
      data: {
        submission: payload,
      },
      state: "application",
      platform: store.getters["platform/platformId"],
    };
    return axios
      .post(`/alien/${getters["getCurrentRole"]}`, data)
      .then((res) => {
        const submissionId = res.data.submissionId;
        commit(SET_CURRENT, res.data);
        router.push(`/${getters["getCurrentRole"]}/${submissionId}`);
      });
  },
  editSubmission({ state, getters, commit }, payload) {
    const data = {
      ...state.current.data,
      submission: payload,
    };

    const combinedPayload = {
      ...state.current,
      data,
    };

    const submissionId = state.current.submissionId;

    if (!combinedPayload.platform) {
      combinedPayload.platform = store.getters["platform/platformId"];
    }

    return axios
      .patch(
        `/alien/${getters["getCurrentRole"]}/${submissionId}`,
        combinedPayload
      )
      .then((res) => {
        commit(SET_CURRENT, res.data);
      });
  },
  editSubmissionState({ state, getters, commit }, payload) {
    const combinedPayload = {
      ...state.current,
      state: payload,
    };

    const submissionId = state.current.submissionId;

    if (!combinedPayload.platform) {
      combinedPayload.platform = store.getters["platform/platformId"];
    }

    return axios
      .patch(
        `/alien/${getters["getCurrentRole"]}/${submissionId}`,
        combinedPayload
      )
      .then((res) => {
        commit(SET_CURRENT, res.data);
      });
  },
  clearCurrent({ commit }) {
    commit(SET_CURRENT, {});
  },
  editStatus({ state, commit, getters }, payload) {
    var data = {
      ...state.current.data,
      status: payload,
    };
    return axios
      .post(`/alien/admin/submissions?entity=${getters["getCurrentRole"]}`, {
        ...state.current,
        data,
      })
      .then((response) => commit(SET_CURRENT, response.data));
  },
  createAdvisorsObjects({ state, dispatch }, payload) {
    const submissionId = state?.current?.submissionId;
    return Promise.all(
      payload.map(async (item) => {
        if (
          !state.advisorsInformation.some((advisor) => advisor.type === item)
        ) {
          return await axios.post("/alien/advisors", {
            type: item,
            status: "shortlist",
            investeeSubmissionId: submissionId,
          });
        }
      })
    ).then(() => {
      dispatch("setAdvisorInformation", submissionId);
    });
  },
  setAdvisorInformation({ commit }, id) {
    return axios
      .get(`/alien/advisors?investeeSubmissionId=${id}`)
      .then((response) => {
        commit(SET_ADVISORS_INFO, response.data.items);
      });
  },
  updateAdvisorObject({ dispatch }, payload) {
    axios.patch("/alien/advisors/" + payload._id, payload).then(() => {
      dispatch("setAdvisorInformation", payload.investeeSubmissionId);
    });
  },
  setIntermediaryList({ commit }, payload) {
    return axios
      .get(
        `/alien/available-intermediaries?platform=${store.getters["platform/platformId"]}`,
        { params: { type: payload } }
      )
      .then((response) => {
        commit(SET_INTERMEDIARY_LIST, {
          list: response.data.items,
          type: payload,
        });
      });
  },
  setMatchList({ commit, rootGetters }, payload) {
    const submissionId = payload;
    const platform = rootGetters["platform/platformId"];
    return axios
      .get("/alien/matches", {
        params: { investeeSubmissionId: submissionId, platform },
      })
      .then((response) => {
        commit(SET_MATCH_LIST, response.data.items);
      });
  },
  updateMatchObject({ dispatch }, payload) {
    return axios.patch("/alien/match/" + payload._id, payload).then(() => {
      dispatch("setMatchList", payload.investeeSubmissionId);
    });
  },
  getMatchInfo(_, payload) {
    return axios.get("/alien/match/" + payload);
  },
  getInvesteeInformation(_, payload) {
    return axios.get("/alien/submissions/" + payload, {
      params: {
        entity: "investee",
      },
    });
  },
  getUserPreferences({ rootGetters }, payload) {
    const platform = rootGetters["platform/platformId"];

    return axios.get(`alien/${platform}/accounts/` + payload);
  },
  async setAccountData({ commit, rootGetters }, id) {
    const platform = rootGetters["platform/platformId"];
    const response = await axios.get(`/alien/${platform}/accounts/${id}`);
    const accountData = _.get(response, "data");
    commit(SET_CURRENT, accountData);
  },
  async submitAccountData({ commit, state, rootGetters }, data) {
    const platform = rootGetters["platform/platformId"];
    const account = { ...state.current, data };

    const response = await axios.patch(
      `/alien/${platform}/accounts/${state.current.userId}`,
      account
    );
    commit(SET_CURRENT, response.data);
  },
  async editAccountState({ state, commit, rootGetters }, payload) {
    const platform = rootGetters["platform/platformId"];

    const combinedPayload = {
      ...state.current,
      data: {
        ...state.current.data,
        investee: { ...state.current.data.investee, state: payload },
      },
    };

    return axios
      .patch(
        `/alien/${platform}/accounts/${state.current.userId}`,
        combinedPayload
      )
      .then((res) => {
        commit(SET_CURRENT, res.data);
      });
  },
};

const getters = {
  getAll: (state) => {
    return state.list.map((item) => {
      return {
        ...item.data,
        _id: item._id,
        submissionId: item.submissionId,
        state: item.state,
        userId: item.userId,
      };
    });
  },
  getCurrentSubmission: (state) => {
    return state.current;
  },
  getById: (state) => {
    return state.current?.data;
  },
  currentSubmissionState: (state) => {
    return state.current?.state;
  },
  currentSubmissionId: (state) => state.current?.submissionId,
  getCurrentRole: () => {
    const currentPath = router.currentRoute.value.fullPath;
    const subStrings = currentPath.split("/");
    return subStrings[1];
  },
  advisorsInformation: (state) => state.advisorsInformation,
  intermediaryListInfo: (state) => state.intermediaryList,
  matchList: (state) => state.matchList,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
