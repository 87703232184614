import axios from "@/core/services/axios";
import _ from "lodash";
import { bottomLinks, getChartData, getStatusCounts } from "./helpers";

const SET_STATUS_COUNT = "SET_STATUS_COUNT";

const state = {
  statusCount: {},
};

const mutations = {
  [SET_STATUS_COUNT](state, payload) {
    state.statusCount = payload;
  },
};

const actions = {
  getNumberOfSubmissionByStatus({ commit, dispatch, rootGetters }) {
    const platform = rootGetters["platform/platformId"];
    const API_REQUEST =
      platform === "fsd"
        ? `/alien/admin/submissions-status`
        : `/alien/admin/${platform}/stats`;
    return axios
      .get(API_REQUEST)
      .then((response) => {
        const matchData = _.get(response, "data.match[0]");
        let matches = [];
        if (matchData) {
          matches = Object.entries(response.data.match[0]).map((item) => {
            return {
              _id: item[0],
              count: item[1],
            };
          });
        }
        commit(SET_STATUS_COUNT, { ...response.data, match: matches });
      })
      .catch((err) => {
        const errorMessage = err.response
          ? err.response.data.message
          : err.message;
        dispatch(
          "message/showErrorMessage",
          {
            mainText: errorMessage,
            buttonText: "Ok",
          },
          { root: true }
        );
      });
  },
};

const getters = {
  investeeCounts: (state, _, __, rootGetters) => {
    const platform = rootGetters["platform/config"]?.path;
    return getStatusCounts(state, "investee", platform);
  },
  investorCounts: (state, _, __, rootGetters) => {
    const platform = rootGetters["platform/config"]?.path;

    return getStatusCounts(state, "investor", platform);
  },
  intermediaryCounts: (state, _, __, rootGetters) => {
    const platform = rootGetters["platform/config"]?.path;

    return getStatusCounts(state, "intermediary", platform);
  },
  matchesCounts: (state, _, __, rootGetters) => {
    const platform = rootGetters["platform/config"]?.path;

    return getStatusCounts(state, "match", platform);
  },
  investeesChartInfo: (state, _, __, rootGetters) => {
    const platform = rootGetters["platform/config"]?.path;

    const data = state.statusCount?.investee || {};
    const dataSet = getChartData(data, "investee", platform);

    return { series: [{ name: "count", data: dataSet }] };
  },

  investorsChartInfo: (state, _, __, rootGetters) => {
    const platform = rootGetters["platform/config"]?.path;

    const data = state.statusCount?.investor || {};
    const dataSet = getChartData(data, "investor", platform);

    return { series: [{ name: "count", data: dataSet }] };
  },

  intermediariesChartInfo: (state, _, __, rootGetters) => {
    const platform = rootGetters["platform/config"]?.path;

    const data = state.statusCount?.intermediary || {};
    const dataSet = getChartData(data, "intermediary", platform);

    return { series: [{ name: "count", data: dataSet }] };
  },

  matchesChartInfo: (state, _, __, rootGetters) => {
    const platform = rootGetters["platform/config"]?.path;

    const data = state.statusCount?.match || {};
    const dataSet = getChartData(data, "match", platform);

    return { series: [{ name: "count", data: dataSet }] };
  },
  bottomLinks: (state, _, __, rootGetters) => {
    const platform = rootGetters["platform/config"]?.path;

    return bottomLinks(platform);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
