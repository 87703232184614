import en from "@vueform/vueform/locales/en";
import bootstrap from "@vueform/vueform/themes/bootstrap";
import fetchedConfig from "./build/fetched-config.json";

const isSharepointUpload = fetchedConfig.features.sharepoint;

let FilesUpload;

if (isSharepointUpload) {
  FilesUpload =
    require("vdr-framework/src/custom-components/files-management-sharepoint/vueform/files-upload.vue").default;
} else {
  FilesUpload =
    require("vdr-framework/src/custom-components/files-management/vueform/files-upload.vue").default;
}

export default {
  theme: bootstrap,
  locales: { en },
  locale: "en",
  elements: [FilesUpload],
};
