const states = {
  application: {
    value: "application",
    label: "New",
    parent: "new",
    color: "inProgress",
    chartColor: "#eaaf39",
    order: 1,
  },
  qualified: {
    value: "qualified",
    label: "Qualified",
    parent: "inProgress",
    color: "inProgress",
    chartColor: "#eaaf39",
    order: 2,
  },
  review: {
    value: "review",
    label: "Prosper Validation",
    parent: "inProgress",
    color: "inProgress",
    chartColor: "#7239EA",
    order: 3,
  },
  unqualified: {
    value: "unqualified",
    label: "Rejected",
    parent: "closed",
    color: "denied",
    chartColor: "#c0c0c0",
    order: 4,
  },
  preparation: {
    value: "preparation",
    label: "Preparation",
    parent: "inProgress",
    color: "inProgress",
    chartColor: "#50CD89",
    order: 5,
  },
  ready: {
    value: "ready",
    label: "Ready to publish",
    parent: "inProgress",
    color: "inProgress",
    chartColor: "#eaaf39",
    order: 5,
  },
  published: {
    value: "published",
    label: "Published",
    parent: "published",
    color: "done",
    chartColor: "#009EF7",
    order: 6,
  },
  closed: {
    value: "closed",
    label: "Success",
    parent: "closed",
    color: "done",
    chartColor: "#009EF7",
    order: 8,
  },
  archived: {
    value: "archived",
    label: "Archived",
    parent: "closed",
    color: "denied",
    chartColor: "#c0c0c0",
    order: 9,
  },
};

module.exports = states;
