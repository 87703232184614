const states = {
  new: {
    value: "new",
    label: "New",
    parent: "new",
    color: "inProgress",
    chartColor: "#eaaf39",
    order: 1,
  },
  review: {
    value: "review",
    label: "Ready for review",
    parent: "new",
    color: "inProgress",
    chartColor: "#eaaf39",
    order: 2,
  },
  active: {
    value: "active",
    label: "Active",
    parent: "published",
    color: "done",
    chartColor: "#009EF7",
    order: 3,
  },
  unqualified: {
    value: "unqualified",
    label: "Unqualified",
    parent: "closed",
    color: "denied",
    chartColor: "#c0c0c0",
    order: 4,
  },
  archived: {
    value: "archived",
    label: "Archived",
    parent: "closed",
    color: "denied",
    chartColor: "#c0c0c0",
    order: 5,
  },
};

module.exports = states;
