import {
  getStatusName,
  getStatusObjectByName,
  investeeStatusMap,
  intermediaryStatusMap,
  investorStatusMap,
} from "asokoinsight-helpers-business-logic/src/dff-helpers";

import { matchStatusMap } from "asokoinsight-helpers-business-logic/src/dff-helpers/status-maps";

const orderPerRole = {
  investee: ["Onboarding", "Business review", "Deal preparation", "Investment"],
  investor: ["Review", "Onboarding", "Investment"],
  intermediary: ["Review", "Onboarding", "Capacity Building", "Deal Support"],
  match: ["interest", "talks", "exclusive"],
};

const bottomLinks = {
  investee: [
    {
      label: "Actions needed",
      links: [
        {
          link: "/investees?count=10&offset=0&step=3",
          status: "Business review",
          text: "Complete Business Diagnosis",
          bg: "bg-info",
          entity: "investee",
          disabled: false,
        },
        {
          link: "/investees?count=10&offset=0&step=4",
          status: "Deal preparation",
          text: "Assign Intermediary",
          bg: "bg-success",
          entity: "investee",
          disabled: false,
        },
      ],
    },
    {
      label: "Exited Workflow",
      links: [
        {
          link: "/investees?count=10&offset=0&step=2&view=unqualified",
          status: "Unqualified",
          text: "Unqualified",
          bg: "bg-secondary",
          entity: "investee",
          disabled: false,
        },
        {
          link: "/investees?count=10&offset=0&step=2&view=archived",
          status: "Archived",
          text: "Archived",
          bg: "bg-secondary",
          entity: "investee",
          disabled: false,
        },
      ],
    },
  ],
  investor: [
    {
      label: "Actions needed",
      links: [
        {
          link: "/investors?count=10&offset=0&step=2&view=review",
          status: "Review",
          text: "Complete Applications Review",
          bg: "bg-info",
          entity: "investor",
          disabled: false,
        },
      ],
    },
    {
      label: "Exited Workflow",
      links: [
        {
          link: "/investors?count=10&offset=0&step=3&view=unqualified",
          status: "Unqualified",
          text: "Unqualified",
          bg: "bg-secondary",
          entity: "investor",
          disabled: false,
        },
        {
          link: "/investors?count=10&offset=0&step=3&view=archived",
          status: "Archived",
          text: "Archived",
          bg: "bg-secondary",
          entity: "investor",
          disabled: false,
        },
      ],
    },
  ],
  intermediary: [
    {
      label: "Actions needed",
      links: [
        {
          link: "/intermediaries?count=10&offset=0&step=2&view=review",
          status: "Review",
          text: "Complete Applications Review",
          bg: "bg-info",
          entity: "intermediary",
          disabled: false,
        },
      ],
    },
    {
      label: "Exited Workflow",
      links: [
        {
          link: "/intermediaries?count=10&offset=0&step=3&view=unqualified",
          status: "Unqualified",
          text: "Unqualified",
          bg: "bg-secondary",
          entity: "intermediary",
          disabled: false,
        },
        {
          link: "/intermediaries?count=10&offset=0&step=3&view=archived",
          status: "Archived",
          text: "Archived",
          bg: "bg-secondary",
          entity: "intermediary",
          disabled: false,
        },
      ],
    },
  ],
  match: [
    {
      label: "Actions needed",
      links: [
        {
          link: "/matches?count=10&offset=0&status=partial",
          status: "partial",
          text: "Partially closed",
          bg: "bg-info",
          entity: "match",
          disabled: false,
        },
      ],
    },
    {
      label: "Exited Workflow",
      links: [
        {
          link: "/matches?count=10&offset=0&status=closed",
          status: "closed",
          text: "Closed successfully",
          bg: "bg-secondary",
          entity: "match",
          disabled: false,
        },
      ],
    },
  ],
};

const getChartData = (data, entity = "investee") => {
  const order = orderPerRole[entity];
  const dataByStatus = {};
  for (let item in data) {
    const status = getStatusName(data[item]._id, entity);
    if (!status) {
      continue;
    }
    dataByStatus[status.name] = {
      name: status.label || status.name,
      chartColor: status.chartColor,
      count: data[item].count,
    };
  }

  const dataSet = order.map((item) => {
    let statusData = dataByStatus[item];
    if (!statusData) {
      statusData = getStatusObjectByName(item, entity);
    }
    return {
      x: statusData.name,
      y: statusData.count || 0,
      fillColor: statusData.chartColor,
    };
  });
  return dataSet;
};

const getStatusCounts = (state, entity = "investee") => {
  const response = {};

  const maps = {
    investee: investeeStatusMap,
    intermediary: intermediaryStatusMap,
    investor: investorStatusMap,
    match: matchStatusMap,
  };

  const map = maps[entity];

  for (let item in map) {
    const name = map[item].name;
    response[name] = { count: 0 };
  }

  const data = state.statusCount?.[entity];

  for (let item in data) {
    const status = getStatusName(data[item]._id, entity);
    if (!status) {
      continue;
    }
    response[status.name].count = data[item].count;
  }

  return response;
};

export { getChartData, getStatusCounts, bottomLinks };
