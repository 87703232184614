const states = {
  application: {
    value: "application",
    label: "New",
    parent: "new",
    color: "inProgress",
    order: 1,
  },
  review: {
    value: "review",
    label: "Review",
    parent: "inProgress",
    color: "inProgress",
    chartColor: "#a0fcca",
    order: 2,
  },
  onboarding: {
    value: "onboarding",
    label: "Prosper validation",
    parent: "inProgress",
    color: "inProgress",
    chartColor: "#4fce8a",
    order: 3,
  },
  published: {
    value: "published",
    label: "Deal support",
    parent: "published",
    color: "done",
    chartColor: "#1aa85d",
    order: 5,
  },
  unqualified: {
    value: "unqualified",
    label: "Unqualified",
    parent: "closed",
    color: "denied",
    chartColor: "#c0c0c0",
    order: 6,
  },
  archived: {
    value: "archived",
    label: "Archived",
    parent: "closed",
    color: "denied",
    chartColor: "#c0c0c0",
    order: 7,
  },
};

module.exports = states;
