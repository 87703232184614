import awf from "../platforms/awf/locale";
import Default from "../platforms/default/locale";
import afr from "../platforms/afr/locale";

const messages = {
  awf,
  afr,
  default: Default,
};

export default messages;
