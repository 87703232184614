export default {
  seekersList: {
    header: {
      title: "Client List",
      counter: "Clients",
    },
    list: {
      displayName: "Organisation Name",
      country: "Country",
      leadName: "Lead name",
      leadEmail: "Lead email",
    },
  },
  investeeList: {
    header: {
      title: "Project List",
      counter: "Project",
    },
  },
  investorList: {
    header: {
      title: "Investors List",
      counter: "Investors",
    },
  },
  matchList: {
    header: {
      title: "Investor Interests List",
      counter: "interests",
    },
  },
  dashboard: {
    charts: {
      match: { subtitle: "Matched Projects" },
    },
  },
  investeeDetail: {
    header: {
      backButton: "Back to projects",
      role: "Project",
    },
  },
  seekerDetail: {
    header: {
      backButton: "Back to clients",
      role: "Client",
    },
  },
};
