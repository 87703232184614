const states = {
  interest: {
    value: "interest",
    label: "Investor interest",
    color: "inProgress",
    chartColor: "#7239ea",
    order: 1,
  },
  talks: {
    value: "talks",
    label: "Talks",
    color: "inProgress",
    chartColor: "#7239ea",
    order: 2,
  },
  closed: {
    value: "closed",
    label: "Closed successfully",
    chartColor: "#7239ea",
    color: "done",
    order: 3,
  },
  abandon: {
    value: "abandon",
    label: "Archived",
    color: "denied",
    order: 4,
  },
};

module.exports = states;
