import axios from "@/core/services/axios";
export const SET_MATCH_LIST = "SET_MATCH_LIST";

const state = {
  matchList: [],
};

const mutations = {
  [SET_MATCH_LIST](state, payload) {
    state.matchList = payload;
  },
};

const actions = {
  fetchListData({ commit, rootGetters }, path = "/alien/admin/match") {
    const platform = rootGetters["platform/platformId"];
    return axios.get(path, { params: { platform } }).then((response) => {
      commit(SET_MATCH_LIST, response.data.items);
      return response;
    });
  },
  getMatchInfo({ rootGetters }, payload) {
    const platform = rootGetters["platform/platformId"];
    return axios.get("/alien/match/" + payload, { params: { platform } });
  },
  addDealMatch({ rootGetters }, payload) {
    const platform = rootGetters["platform/platformId"];
    return axios.post("/alien/match", {
      status: "inProgress",
      platform,
      investeeSubmissionId: payload.investeeId,
      investorSubmissionId: payload.investorId,
    });
  },
};

const getters = {
  matchList: (state) => state.matchList,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
