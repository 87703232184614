import * as dffHelpers from "./dff-helpers";
import * as defaultHelpers from "./default";
import * as awfHelpers from "./awf-helpers";

const getChartData = (state, role, platform) => {
  const functionsMap = {
    dff: dffHelpers.getChartData,
    awf: awfHelpers.getChartData,
  };
  return functionsMap[platform]
    ? functionsMap[platform](state, role)
    : defaultHelpers.getChartData(state, role);
};

const getStatusCounts = (state, role, platform) => {
  const functionsMap = {
    dff: dffHelpers.getStatusCounts,
    awf: awfHelpers.getStatusCounts,
  };
  return functionsMap[platform]
    ? functionsMap[platform](state, role)
    : defaultHelpers.getStatusCounts(state, role);
};

const bottomLinks = (platform) => {
  const linksMap = {
    dff: dffHelpers.bottomLinks,
    awf: awfHelpers.bottomLinks,
  };
  return linksMap[platform] || defaultHelpers.bottomLinks;
};

export { getChartData, getStatusCounts, bottomLinks };
