export default {
  seekersList: {
    header: {
      title: "Investee List",
      counter: "Investees",
    },
    list: {
      displayName: "Company Name",
      country: "Country",
      leadName: "Lead name",
      leadEmail: "Lead email",
    },
  },
  investeeList: {
    header: {
      title: "Investee List",
      counter: "Investees",
    },
  },
  investorList: {
    header: {
      title: "Investors List",
      counter: "Investors",
    },
  },
  intermediaryList: {
    header: {
      title: "Intermediary List",
      counter: "Intermediaries",
    },
  },
  matchList: {
    header: {
      title: "Investor Interests List",
      counter: "interests",
    },
  },
  dashboard: {
    charts: {
      match: { subtitle: "Matched deals" },
    },
  },
  investeeDetail: {
    header: {
      backButton: "Back to investees",
      role: "Investee",
    },
  },
  seekerDetail: {
    header: {
      backButton: "Back to investees",
      role: "Investee",
    },
  },
  bdrAcronym: "BDR",
  bdrName: "Business Diagnosis Report",
};
